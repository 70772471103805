import React from 'react';
import Layout from '../Layout';

const PrivacyPage = () => {
    return (
        <Layout>
            <div></div>
        </Layout>
    )
}

export default PrivacyPage;